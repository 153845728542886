<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Aggiungi armadio</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'warehouse.racks' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <RackForm :value="rack" :feedback.sync="feedback" @submitForm="saveRack" />
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import RackForm from '@/views/components/Form/RackForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    RackForm,
  },
  data () {
    return {
      rack: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'warehouse.racks' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveRack (rackForm) {
      const rack = { ...rackForm };

      this.$api.createRack(rack)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
};

</script>
